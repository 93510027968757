import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CostCenterDailogComponent } from 'src/shared/components/dialog/admin-dialogs/cost-center-dialog/cost-center-dialog';
import { FilterCostCenterSidebarComponent } from './filter-sidebar/filter-cost-center-sidebar.component';
import { CostCenterService } from '../../services/cost-center.service';

export interface PeriodicElement {
  code: string;
  name: string;
  leader: string;
  account: string;
  rate: string;
  date: string;
  lastupdated: string;
}

@Component({
  selector: 'app-cost-center-listing',
  templateUrl: './cost-center-listing.component.html',
})
export class CostCenterListingComponent implements OnInit {
  dataSource = new MatTableDataSource<PeriodicElement>([]);
  displayedColumns: string[] = [
    'code',
    'name',
    'leader',
    'account',
    'rate',
    'date',
    'lastupdated',
  ];
  isSidebarOpen = false;
  isSidepanelOpen = false;
  isDrawerOpen = false;
  drawerWidth = 200;
  isActive = false;
  costCenter: any[] = [];
  accounts: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'code' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  form!: FormGroup;
  element: any;
  loading: boolean = false;
  showActivityLogDrawer: boolean = false;
  isOpen: boolean = false;
  lastSortBy: string = '';
  totalRecords: number = 0;
  canManageResources: boolean = false;
  selectedAccounts: string[] = [];
  selectedCostCenters: string[] = [];
  isFilterApplied: boolean = false;

  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('filter') filterComponent!: FilterCostCenterSidebarComponent;

  constructor(
    private costlistService: CostCenterService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder,
    public dialog: MatDialog
  ) {}

  openFilterDrawer(): void {
    if (this.filterComponent) {
      this.filterComponent.isOpen = !this.filterComponent.isOpen;
    } else {
    }
  }

  ngOnInit(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const cost_centerPermissions = userPermissions.find(
        (p: any) => p.module === 'cost_center'
      );
      if (
        cost_centerPermissions &&
        cost_centerPermissions.permission.includes('view') &&
        cost_centerPermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      }
    }

    this.sortBy = 'code';
    this.orderBy = 'DESC';
    this.getCostList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
    this.form = this.formbuilder.group({
      searchQuery: [''],
      account: [''],
    });
    this.searchCost();
  }

  getCostList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    costCenterID?: string
  ): void {
    this.loading = true;
    this.costlistService
      .getCostList(pageNumber, limit, search, sortBy, orderBy, costCenterID)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.costCenter = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.costCenter.sort((a, b) => {
            return (
              new Date(b.cc_modified_at).getTime() -
              new Date(a.cc_modified_at).getTime()
            );
          });
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Cost Center List request:', error);
          this.loading = false;
        },
      });
  }

  getCostCenterListByAccount(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedAccounts: string[]
  ): void {
    this.loading = true;
    const accountId = selectedAccounts.join('&accountID=');
    this.costlistService
      .getCostList(pageNumber, limit, search, sortBy, orderBy, accountId)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.costCenter = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in  Cost Center List request:', error);
          this.loading = false;
        },
      });
  }

  getCostCenterListByCostCenter(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedCostCenters: string[]
  ): void {
    this.loading = true;
    const centerName = selectedCostCenters.join('&centerName=');
    this.costlistService
      .getCostList(pageNumber, limit, search, sortBy, orderBy, '', centerName)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.costCenter = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Cost Center List request:', error);
          this.loading = false;
        },
      });
  }

  getCostCenterListByAllFilters(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedAccounts: string[],
    selectedCostCenters: string[]
  ): void {
    this.loading = true;
    const accountId = selectedAccounts.join('&accountID=');
    const centerName = selectedCostCenters.join('&centerName=');
    this.costlistService
      .getCostList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        accountId,
        centerName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.costCenter = response.data.records;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Cost Center List request:', error);
          this.loading = false;
        },
      });
  }

  handleFilterApplied(filters: any): void {
    this.selectedAccounts = filters.selectedAccounts || [];
    this.selectedCostCenters = filters.selectedCostCenters || [];
    if ( this.selectedAccounts.length > 0 &&  this.selectedCostCenters.length > 0) {
      this.getCostCenterListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedAccounts,
        this.selectedCostCenters
      );
    } else if ( this.selectedAccounts.length > 0) {
      this.getCostCenterListByAccount(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedAccounts
      );
    } else if ( this.selectedCostCenters.length > 0) {
      this.getCostCenterListByCostCenter(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedCostCenters
      );
    } else {
      this.getCostList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
    this.isFilterApplied = filters && Object.keys(filters).length > 0;
  }

  onSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';
    const accountId = this.selectedAccounts.join('&accountID=');
    const centerName = this.selectedCostCenters.join('&centerName=');

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.costlistService
      .getCostList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        accountId,
        centerName
      )
      .subscribe({
        next: (data: any) => {
          this.costCenter = data?.data?.records || [];
          this.totalRecords = data?.data?.count || 0;
          this.dataSource.data = this.costCenter;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Cost Center List request:', error);
        },
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getCostList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy || 'ASC'
    );
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(CostCenterDailogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getCostList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      }
    });
  }

  searchCost(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getCostList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      });
  }
}
